import { CellContext } from '@tanstack/react-table'
import * as React from 'react'
import cn from 'classnames'

export type SkeletonCellProps = {
  twoLines?: boolean
  avatar?: boolean
  context?: CellContext<unknown, unknown>
}

export const SkeletonCell = ({
  twoLines,
  avatar,
  context,
}: SkeletonCellProps) => {
  const delay = !context
    ? 0
    : context.row.index * 100 +
      context.table.getAllColumns().indexOf(context.column) * 100

  return (
    <div
      className={cn(
        'flex flex-row gap-x-3 justify-start items-center animate-pulse'
      )}
      style={{ animationDelay: `${delay}ms` }}
    >
      {avatar && <div className="w-8 h-8 rounded-full bg-gray-100 shrink-0" />}
      <div className="flex flex-col grow min-w-[150px] gap-2">
        <div
          className={cn(
            'h-2 rounded-full bg-gray-100',
            twoLines ? 'w-2/3' : 'w-1/2'
          )}
        />
        {twoLines && <div className="w-1/2 h-2 rounded-full bg-gray-50" />}
      </div>
    </div>
  )
}
